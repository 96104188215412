<template>
    <div id="content">
        <div class="container">
            <div v-if="loading" class="d-flex align-items-center justify-content-center mt-5">
                <span class="spinner-grow spinner-grow-sm mx-3" style="width: 2rem; height: 2rem" role="status"
                    aria-hidden="true"></span><span class="text-md">Loading....</span>
            </div>
            <div v-else>
                <div class="row">
                    <div class="col-xl-8">
                        <h1>Need help finding your replacement model?</h1>
                        <p>Enter the model or name of your existing appliance below to find your replacement model.</p>
                        <form class="form" @submit.prevent="getProducts" novalidate>
                            <div class="input-group mb-4">
                                <input type="text" class="form-control" placeholder="Search product name or code"
                                    v-model="query" @input="query = query.replace(/[\/\\]/g, '')" />
                                <div class="input-group-prepend">
                                    <button type="submit" class="btn btn-alt btn-primary" id="input-search-button"
                                        :disabled="query.length == 0">
                                        Search <i class="fa fa-angle-right pl-2" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            <div v-if="showNotFoundMessage" class="alert alert-danger" role="alert">
                                Unfortunately, we couldn’t find your model number. Our expert product advisors will be
                                happy
                                to
                                assist you in finding the right product, please contact us on <a href="tel:03448921097">
                                    03448921097</a>
                            </div>
                            <p>Note: Enter the model number not the ENR number.</p>
                            <p>Example KUR15A50GB/02 will not display any results, KUR15A50GB
                                will show the replacement options.</p>

                        </form>

                    </div>


                </div>

                <div v-if="searching" class="d-flex justify-content-center pt-5">
                    <span class="spinner-grow spinner-grow-sm" style="width: 4rem; height: 4rem" role="status"
                        aria-hidden="true"></span>
                </div>
                <div v-else-if="product && product.id" class="search-result">
                    <h2>Showing results for "{{ requestedQuery }}".</h2>
                    <div class="inner-wrapper">
                        <div class="result-column mb-5 mb-lg-0">
                            <h3>Your searched model</h3>
                            <div class="product-description">
                                <div class="product-wrapper">
                                    <div class="product">

                                        <div class="text-center mb-4">
                                            <a v-if="!successorProduct.id"
                                                :href="`https://bosch-home.co.uk/store/${product.code}`"
                                                class="btn btn-alt btn-primary" id="input-search-button"
                                                target="_parent">
                                                Click here to buy online <i class="fa fa-angle-right pl-2"
                                                    aria-hidden="true"></i>
                                            </a>
                                        </div>
                                        <div class="slider product-slider">
                                            <figure>
                                                <img :src="'https://media3.bosch-home.com/Product_Shots/600x337/' +
                                                    product.featured_image +
                                                    '.jpg'
                                                    " :alt="product.code" style="width: auto; height: auto" />
                                            </figure>
                                        </div>


                                        <div class="details">
                                            <h3>
                                                {{ product.brand.name }} {{ product.series }}
                                                {{ product.code }} {{ product.name }}
                                            </h3>
                                            <div class="meta">
                                                <span class="product-code">Product code: {{ product.code }}</span>
                                            </div>
                                            <span v-if="product.is_featured" class="featured-tag">Buy Direct
                                                Model</span>
                                            <div class="accordion-wrapper">
                                                <div class="accordion" id="accordion1">
                                                    <div class="mb-4">
                                                        <button data-toggle="collapse" data-target="#collapse1"
                                                            aria-expanded="true" aria-controls="collapse1"
                                                            class="collapsed">
                                                            Specification
                                                        </button>
                                                        <div id="collapse1" data-parent="#accordion1" class="collapse">
                                                            <div class="accordion-body">


                                                                <perfect-scrollbar>
                                                                    <div
                                                                        v-if="product.specifications == '<div>\n  <ul></ul>\n</div>'">
                                                                        Product
                                                                        Specification currently
                                                                        not
                                                                        available</div>
                                                                    <p v-else v-html="product.specifications"></p>
                                                                </perfect-scrollbar>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="mb-4">
                                                        <button data-toggle="collapse" data-target="#collapse2"
                                                            aria-expanded="true" aria-controls="collapse2"
                                                            class="collapsed">
                                                            Product Info
                                                        </button>
                                                        <div id="collapse2" data-parent="#accordion1" class="collapse">

                                                            <div class="accordion-body">

                                                                <perfect-scrollbar>
                                                                    <div v-if="product.description == '<div></div>'">
                                                                        Product
                                                                        Information currently
                                                                        not available</div>
                                                                    <div v-else v-html="product.description"></div>
                                                                </perfect-scrollbar>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="result-column bg-1">
                            <h3>Replacement model</h3>
                            <div v-if="successorProduct && successorProduct.name != ''" class="product-description">
                                <div class="product-wrapper">
                                    <div class="product">

                                        <div class="text-center mb-4">
                                            <a :href="`https://bosch-home.co.uk/store/${successorProduct.code}`"
                                                class="btn btn-alt btn-primary text-center" id="input-search-button"
                                                target="_parent">
                                                Click here to buy online <i class="fa fa-angle-right pl-2"
                                                    aria-hidden="true"></i>
                                            </a>
                                        </div>
                                        <div class="slider product-slider">
                                            <figure>
                                                <img :src="'https://media3.bosch-home.com/Product_Shots/600x337/' +
                                                    successorProduct.featured_image +
                                                    '.jpg'
                                                    " :alt="successorProduct.code" style="width: auto; height: auto" />
                                            </figure>
                                        </div>


                                        <div class="details">
                                            <h3>
                                                {{ successorProduct.brand.name }} {{ successorProduct.series }}
                                                {{ successorProduct.code }} {{ successorProduct.name }}
                                            </h3>
                                            <div class="meta">
                                                <span class="product-code">Product code: {{ successorProduct.code
                                                    }}</span>
                                            </div>
                                            <span v-if="successorProduct.is_featured" class="featured-tag">Buy Direct
                                                Model</span>
                                            <div class="accordion-wrapper">
                                                <div class="accordion" id="accordion2">
                                                    <div class="mb-4">
                                                        <button data-toggle="collapse" data-target="#collapse3"
                                                            aria-expanded="true" aria-controls="collapse3"
                                                            class="collapsed">
                                                            Specification
                                                        </button>
                                                        <div id="collapse3" data-parent="#accordion2" class="collapse">
                                                            <div class="accordion-body">


                                                                <perfect-scrollbar>
                                                                    <div
                                                                        v-if="product.specifications == '<div>\n  <ul></ul>\n</div>'">
                                                                        Product
                                                                        Specification currently
                                                                        not
                                                                        available</div>
                                                                    <p v-else v-html="product.specifications"></p>
                                                                </perfect-scrollbar>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="mb-4">
                                                        <button data-toggle="collapse" data-target="#collapse4"
                                                            aria-expanded="true" aria-controls="collapse4"
                                                            class="collapsed">
                                                            Product Info
                                                        </button>
                                                        <div id="collapse4" data-parent="#accordion2" class="collapse">

                                                            <div class="accordion-body">

                                                                <perfect-scrollbar>
                                                                    <div v-if="product.description == '<div></div>'">
                                                                        Product
                                                                        Information currently
                                                                        not available</div>
                                                                    <div v-else v-html="product.description"></div>
                                                                </perfect-scrollbar>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                    </div>

                                </div>
                            </div>
                            <div v-else class="pt-4">
                                <div class="box-1">
                                    <h6>No direct replacement or
                                        similar products available.</h6>
                                    <p> Our expert product advisors
                                        will be happy to assist you in finding the right product, please contact us on
                                        <a class="text-underline" href="tel:03448921097">03448921097</a>
                                    </p>
                                    <!-- <a href="mailto:cog-drsengineerleads@bshg.com" class="btn btn-alt btn-primary"
                                        id="input-search-button">
                                        Contact us <i class="fa fa-angle-right pl-2" aria-hidden="true"></i>
                                    </a> -->
                                </div>
                            </div>
                        </div>
                        <!-- <div>
                            <header class="mb-4">
                                <h2>Replacement model</h2>
                                <p>Please see below the latest replacement based on your recent search. Click to view
                                    the
                                    product and where and how to purchase.</p>
                            </header>

                            <div v-if="successorProduct && successorProduct.name != ''" class="successor-product">
                                <div class="product">
                                    <div class="row">
                                        <div class="col-lg-4">
                                            <div class="image">
                                                <img :src="'https://media3.bosch-home.com/Product_Shots/600x337/' +
                                                    successorProduct.featured_image +
                                                    '.jpg'
                                                    " alt="" />
                                            </div>
                                        </div>
                                        <div class="col-lg-8 d-flex flex-column">

                                            <div class="details">
                                                <h3>
                                                    {{ successorProduct.brand.name }}
                                                    {{ successorProduct.series }}
                                                    {{ successorProduct.code }}
                                                    {{ successorProduct.name }}
                                                </h3>
                                                <p>Product code: {{ successorProduct.code }}</p>
                                            </div>
                                            <button type="button" @click="getProduct(successorProduct.id)"
                                                class="btn btn-alt btn-primary align-self-start mt-auto"
                                                id="input-search-button">
                                                View <i class="fa fa-angle-right pl-2" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div v-else class="successor-product mb-4 mb-lg-0">
                                <div class="product">
                                    <div class="details">
                                        <p>No direct replacement available. Please use similar products or the product
                                            finder
                                            to offer a replacement.</p>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProductService from '../../../services/product.service';
export default {
    data() {
        return {
            loading: false,
            searching: false,
            product: {},
            successorProduct: {},
            query: '',
            requestedQuery: '',
            showNotFoundMessage: false,
            user: {
                email: 'mks-gbsmcsb@bshg.com', password: 'July2024!'
            }
        }
    },
    created() { this.generateAuthToken() },
    methods: {
        getProducts: function () {
            this.searching = true
            this.showNotFoundMessage = false
            this.requestedQuery = this.query
            // restricting product search to Bosch (id: 1) only
            ProductService.list({ search: this.query, brand__in: 1 }).then((response) => {
                if (response.count > 0) {
                    this.product = response.results[0];
                    ProductService.getSuccessor(this.product.id).then(
                        (successor) => {
                            this.successorProduct = successor;
                            this.searching = false;
                            //adding a timeout here as it is taking 10 miliseconds to paint the dom and making the page scrollable
                            setTimeout(() => window.scrollBy({ top: 400, behavior: 'smooth' }), 10)
                        }
                    );
                } else {
                    this.searching = false;
                    this.product = {};
                    this.showNotFoundMessage = true;
                }
            }).then(() => ProductService.searchEvent(this.query));
        },
        generateAuthToken() {
            this.loading = true;
            this.$store.dispatch("login", this.user).then(
                () => this.loading = false
            );
        }
    }
}
</script>

<style lang="scss" scoped>
#content {
    color: #000;
    padding: 100px 0;
}

@media (min-width: 1300px) {
    .container {
        max-width: 1200px;
    }
}

.form {
    .form-control {
        height: 55px;
    }
}

h1 {
    color: #000;
    font-size: 2.2rem;
}

h2 {
    font-size: 1.9rem;
    color: #000;
    font-weight: 700;
    margin-bottom: 20px;
}

h3 {
    font-size: 1.35rem;
    color: #000;
    font-weight: 700;
}

p {
    color: #000;

}

.alert {
    a {
        text-decoration: underline;
    }
}

.text-md {
    font-size: 20px;
}

.btn-primary {
    background-color: #007BC0 !important;

}

.search-result {
    padding-top: 25px;
    border-top: 1.5px solid #EFF1F2;



    .inner-wrapper {
        display: flex;
    }

    h2 {
        color: #007BC0;
        font-size: 38px;
        margin-bottom: 66px;
    }

    .result-column {
        padding: 35px 60px 60px 60px;
        flex-grow: 1;
        width: 50%;

        >h3 {
            text-align: center;
            margin-bottom: 32px;
        }
    }
}

@media(max-width: 991px) {
    .search-result {
        .inner-wrapper {
            display: block;
        }

        h2 {
            font-size: 28px;
        }

        .result-column {
            padding: 24px 16px;
            width: 100%;
        }
    }
}

@media(max-width: 768px) {
    .search-result {
        .inner-wrapper {
            display: block;
        }

        .result-column {
            width: 100%;
        }

        >h3 {
            margin-bottom: 18px;
        }
    }
}

.product .details .btn,
.product .btn {
    background-color: #007BC0 !important;
    border-radius: 0;
    padding: 18px 25px;
    font-size: 17px;
}

.product-wrapper {

    .product-slider {
        box-shadow: none;

        figure {
            width: 398px;
            margin: 0 auto;
            padding: 39px;
            height: 300px;
            margin-bottom: 30px;
        }
    }

    .product {
        .details {
            h3 {
                height: 78px;
            }
        }
    }
}

@media(max-width: 768px) {
    .product-wrapper {
        .product-slider {

            figure {
                width: auto;
                padding: 16px;
                margin-bottom: 12px;
            }
        }

        .product {
            .details {
                h3 {
                    height: auto;
                }
            }
        }
    }
}

.successor-product {
    .product {
        background-color: #F0F1F2;

        & * {
            color: #000 !important;
        }

        .image {
            height: auto !important;
            border: 1px solid #DBDBDB;
            border-radius: 0;
            margin-bottom: 0 !important;
        }

        .details {
            p {
                margin-bottom: 0;
            }
        }

        .btn {
            color: #fff !important;

            i {
                color: #fff !important;
            }
        }
    }
}

// .tabs2 {
//     .nav {
//         background-color: #EFF1F2;
//         padding: 14px 0px;
//         border: 0;

//         li {
//             margin: 0 25px;
//         }

//         a {
//             text-decoration: none;
//             font-size: 1rem;
//             color: #000;
//             position: relative;

//             &::before {
//                 content: '';
//                 width: 100%;
//                 height: 3px;
//                 background: transparent;
//                 position: absolute;
//                 left: 0;
//                 bottom: -17px;
//             }

//             &.active {
//                 color: #007BC0;

//                 &::before {
//                     background-color: #007BC0;
//                 }
//             }
//         }
//     }

//     .tab-pane {
//         padding: 40px 30px;
//     }
// }

.accordion {
    button {
        background-color: #EFF1F2;
        padding: 14px 25px;
        border: 0;
        text-decoration: none;
        font-size: 1rem;
        color: #007BC0;
        width: 100%;
        text-align: left;
        position: relative;

        &::after {
            content: '\f106';
            font-family: FontAwesome;
            font-size: 22px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 20px;
            transition: all .5s ease-out;
            line-height: 1;
        }

        &.collapsed {
            color: #000;

            &::after {
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }

    .accordion-body {
        padding: 30px;
    }
}

.bg-1 {
    background-color: #F7F8F8;
}

.box-1 {
    padding: 74px 52px;
    background-color: #007BC0;
    text-align: center;

    h6 {
        font-size: 18px;
        margin-bottom: 75px;
        font-weight: 700;
    }

    p {
        font-size: 16px;

        &:last-of-type {
            margin-bottom: 35px;
        }
    }

    * {
        color: #fff;
    }

    .btn {
        background: #fff !important;
        color: #007BC0;
        border-radius: 0;
        padding: 18px 25px;
        font-size: 17px;

        i {
            color: #007BC0;
        }
    }
}

@media(max-width: 768px) {
    .box-1 {
        padding: 40px 24px;

        h6 {
            margin-bottom: 45px;
        }
    }
}

.text-underline {
    text-decoration: underline;
}
</style>

<style>
@font-face {
    font-family: "Bosch Sans";
    src: url('../../../assets/fonts/BoschSans-Regular.eot');
    src: url('../../../assets/fonts/BoschSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../../assets/fonts/BoschSans-Regular.woff') format('woff'),
        url('../../../assets/fonts/BoschSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Bosch Sans";
    src: url('../../../assets/fonts/BoschSans-Bold.eot');
    src: url('../../../assets/fonts/BoschSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../../assets/fonts/BoschSans-Bold.woff') format('woff'),
        url('../../../assets/fonts/BoschSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

body {
    background-color: #fff;
    font-family: 'Bosch Sans';
    font-size: 14px;
}
</style>